<template>
  <layout>
    <v-progress-linear
      v-if="updatedElements && totalElements && (updatedElements < totalElements)"
      :value="(updatedElements*100)/totalElements"
    ></v-progress-linear>
    <v-container v-if="people" fluid style="height: 100%;">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        direction="top"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="red"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="indigo"
        >
          <v-icon>mdi-star-half</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="update"
          :loading="updating"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-row>
        <v-col cols="3">
          <img style="width: 100%;" v-lazy="imgObj(people.profile_path)">
        </v-col>
        <v-col cols="9">
          <h1>{{people.name}}</h1>
          <h2>Biography</h2>
          <p>{{people.biography}}</p>

          <v-timeline dense>
            <v-timeline-item v-for="job in cast" :key="job.credit_id" :color="job.media_type === 'movie' ? 'primary' : 'success'" :icon="job.media_type === 'movie' ? 'mdi-filmstrip' : 'mdi-television-box'">
              <div v-if="job.media_type === 'movie'">
                {{moment(job.release_date).format('YYYY')}} -
                <router-link :to="'/movie/' + job.id">{{job.title}}</router-link>
                as {{job.character}}
              </div>
              <div v-if="job.media_type === 'tv'">
                {{moment(job.first_air_date).format('YYYY')}} -
                <router-link :to="'/tv/' + job.id">{{job.name}}</router-link>
                as {{job.character}}
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { moviedb } from '../variables';

export default {
  metaInfo: {
    title: 'Person'
  },
  data () {
    return {
      people: null,
      cast: [],
      crew: [],
      fab: false,
      updating: false,
      totalElements: 0,
      updatedElements: 0
    };
  },
  components: {
    Layout
  },
  computed: {},
  methods: {
    moment (date) {
      return moment(date);
    },
    imgObj (path) {
      return {
        src: 'https://assets.le-navet.com/static/800' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    },
    update () {
      this.updating = true;
      let firestore = firebase.firestore();
      let personRef = firestore.collection('persons');
      let batch = firestore.batch();
      let promises = [];

      this.totalElements = 0;
      this.updatedElements = 0;

      let personId = this.$route.params.id;

      promises.push(moviedb.personInfo({ id: personId }).then(personInfo => {
        batch.set(personRef.doc(personId + ''), personInfo);
        moviedb.personCombinedCredits({ id: personId }).then(personCredits => {
          this.totalElements += personCredits.cast.length;
          this.totalElements += personCredits.crew.length;
          for (let j = 0; j < personCredits.cast.length; j++) {
            if (personCredits.cast[j].media_type === 'movie' && personCredits.cast[j].release_date) {
              personCredits.cast[j].date = personCredits.cast[j].release_date;
            }
            if (personCredits.cast[j].media_type === 'tv' && personCredits.cast[j].first_air_date) {
              personCredits.cast[j].date = personCredits.cast[j].first_air_date;
            }
            personRef.doc(personId + '').collection('cast').doc(personCredits.cast[j].credit_id).set(personCredits.cast[j]).finally(() => {
              this.updatedElements++;
            });
          }
          for (let j = 0; j < personCredits.crew.length; j++) {
            if (personCredits.crew[j].media_type === 'movie' && personCredits.crew[j].release_date) {
              personCredits.crew[j].date = personCredits.crew[j].release_date;
            }
            if (personCredits.crew[j].media_type === 'tv' && personCredits.crew[j].first_air_date) {
              personCredits.crew[j].date = personCredits.crew[j].first_air_date;
            }
            personRef.doc(personId + '').collection('crew').doc(personCredits.crew[j].credit_id).set(personCredits.crew[j]).finally(() => {
              this.updatedElements++;
            });
          }
        });
      }));
      Promise.all(promises).then(() => {
        batch.commit();
        this.updating = false;
        this.initialize();
      });
    },
    initialize () {
      let peopleId = this.$route.params.id;
      let firestore = firebase.firestore();
      let personRef = firestore.collection('persons');
      personRef.doc(peopleId + '').get().then(personInfo => {
        if (!personInfo.exists) {
          this.update();
        } else {
          this.people = personInfo.data();
          personInfo.ref.collection('cast').orderBy('date', 'desc').get().then(cast => {
            this.cast = [];
            cast.forEach(job => {
              this.cast.push(job.data());
            });
          });
          personInfo.ref.collection('crew').orderBy('date', 'desc').get().then(crew => {
            this.crew = [];
            crew.forEach(job => {
              this.crew.push(job.data());
            });
          });
        }
      })
    }
  },
  mounted: function () {
    this.initialize();
  }
};
</script>
